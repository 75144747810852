<template>
    <div class="mapBox" ref="mapBox">
        <div id="container"></div>
        <nav v-html="mapData.data.content"></nav>
    </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import * as http from '@/api/API.js'
import { useRoute,useRouter } from 'vue-router'
import base64_ from '@/hooks/useBase64text.js'
const route = useRoute()
const router = useRouter()
let routeData = JSON.parse(base64_.decode(route.params.obj))
const mapBox = ref(null)
const mapData = reactive({
    data:{}
})
onMounted(() => {
    mapBox.value.style.opacity = 0
    var map = new AMap.Map('container', {
        zoom: 20,//级别
        center: [116.330494, 39.982166],//中心点坐标
        viewMode: '3D',//使用3D视图
    });
    // 创建一个 Marker 实例：
    var marker = new AMap.Marker({
        position: new AMap.LngLat(116.330494, 39.982166),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '北京'
    });
    // 将创建的点标记添加到已有的地图实例：
    map.add(marker);
    var infoWindow = new AMap.InfoWindow({
        anchor: 'top-left',
        content: '中国科学院大学中关村校区(教学楼)',
    });
    infoWindow.open(map, [116.330494, 39.982166])
    mapBox.value.style.transform = 'translateY(500px)'
    getLxlist({type_id:routeData.article_id})
})
// 获取联系我们的数据
let getLxlist = async (id) => {
    const {data:res} = await http.get_article_list(id);
    if(res.code == 200){
        mapData.data = res.data.data[0]
        setTimeout(() => {
        mapBox.value.style.opacity = 1
        mapBox.value.style.transform = 'translateY(0)'
        },800)
    }
}

</script>

<style lang="less" scoped>
@maxw:128rem;
.mapBox{
    margin: 0;
    padding: 0;
    transition: ease 1s;
}
#container {
    width: 100%;
    height: 500/@maxw;
}

</style>